import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import { Seo } from '../components/Seo';
import Layout from '../layouts/layout';

const PartnerNda = props => {
    const { language } = useI18next();

    let id = language === 'it' ? '2PACX-1vSeiLAVmGkIjq4EK58DablM-z4srOnsPYF-wD8EY_PZ4qaqMeANcclEtOJoefV7RnSPJ51uFTO6wSu1' : '2PACX-1vTco1eOaL1K8pPsUhOhfZr1b51dvtx1eaaUeF1RX4RCkDzWvzr98W1MO2VMwmaQcZmDaEVypo1bPVQP';
    return <Layout location={props.location}>
        <div className="container p-0" style={{ margin: '60px auto' }}>
            <h1 style={{ marginBottom: '20px' }}><Trans>Partners Non-Disclosure Agreement</Trans></h1>
            <iframe width="100%" height="800px" style={{ border: 0 }}
                    src={`https://docs.google.com/document/d/e/${id}/pub?embedded=true`}></iframe>
        </div>
    </Layout>;
};

export default PartnerNda;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "partner-nda-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'partner-nda-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Non-Disclosure Agreement';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

